<template>
  <div class="w-full h-full">
    <div class="h-full px-6 mx-auto flex flex-col max-w-desktop">
      <LogoHeader />
      <div class="pb-10 mx-auto w-full">
        <h2 class="capitalize mx-auto flex justify-center mb-14">
          {{ $t("message.member-log-in") }}
        </h2>
        <button
          @click="callFederatedSignIn('Facebook')"
          class="mx-auto btn btn-lg btn-clear my-2 mt-4 relative"
        >
          <img
            class="absolute left-4"
            src="@/assets/img/icons/facebook-blue.svg"
            alt="facebook"
          />
          <span>
            {{ $t("message.continue-with", ["Facebook"]) }}
          </span>
        </button>
        <button
          @click="callFederatedSignIn('Google')"
          class="mx-auto btn btn-lg btn-clear my-2 relative"
        >
          <img
            class="absolute left-4"
            src="@/assets/img/icons/google-blue.svg"
            alt="google"
          />
          <span>
            {{ $t("message.continue-with", ["Google"]) }}
          </span>
        </button>
        <h4 class="text-center my-2">
          Or
        </h4>
        <form @submit.prevent="next()">
          <InputWrapper
            class="mt-2"
            name="email"
            type="email"
            :error="hasError('email')"
            :touched="touched('email')"
            :placeholder="$t('message.email-address')"
            :hint="hint('email')"
            v-model="email"
            @input="validateForm()"
            @blur="changed('email')"
          />
          <InputWrapper
            class="mt-2"
            name="password"
            type="password"
            :error="hasError('password')"
            :touched="touched('password')"
            :placeholder="$t('message.password')"
            :hint="hint('password')"
            v-model="password"
            @input="validateForm()"
            @blur="changed('password')"
          />
          <h4
            class="mx-auto text-center w-full text-red my-2"
            v-if="error != ''"
          >
            {{ error }}
          </h4>
          <button type="submit" class="btn btn-lg btn-default my-4">
            <span class="mx-auto" v-if="!loading">
              {{ $t("message.next") }}
            </span>
            <div class="loading w-6 h-6" v-else>
              <Spinner class="sm-button" />
            </div>
          </button>
        </form>
        <div class="flex flex-col">
          <router-link
            :to="{ name: 'RecoverPassword', query: $route.query }"
            class="w-max mx-auto text-center mt-6 text-blue-900 uppercase font-button cursor-pointer"
          >
            {{ $t("message.forgot-your-password-question") }}
          </router-link>
          <router-link
            :to="{ name: 'RequestCode', query: $route.query }"
            class="w-max mx-auto text-center mt-6 text-blue-900 uppercase font-button cursor-pointer"
          >
            {{ $t("message.request-a-new-verification-code") }}
          </router-link>
          <router-link
            :to="{ name: 'SignUpEmailForm', query: $route.query }"
            class="w-max mx-auto text-center mt-6 text-blue-900 uppercase font-button cursor-pointer"
          >
            {{ $t("message.sign-up-for-account") }}
          </router-link>
        </div>
      </div>
      <div class="flex-grow flex items-end">
        <NeedHelp />
      </div>
    </div>
  </div>
</template>

<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import LogoHeader from "@/components/shared/LogoHeader.vue";
import Spinner from "@/components/shared/Spinner.vue";
import NeedHelp from "@/components/register/NeedHelp.vue";
import { Auth } from "@aws-amplify/auth";
import { handleHomepageToast } from "@/utils/homepageToaster.js";

export default {
  name: "SignInPage",
  components: {
    InputWrapper,
    LogoHeader,
    Spinner,
    NeedHelp
  },
  data: () => {
    return {
      email: "",
      loading: false,
      password: "",
      error: "",
      validation: {},
      showAllErrors: false,
      step: 1
    };
  },
  mounted() {
    handleHomepageToast(this);
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/currentUser"];
    },
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    }
  },
  methods: {
    callFederatedSignIn(type) {
      Auth.federatedSignIn({ provider: type });
    },
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (this.validation[key].touched == null) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.showAllErrors = true;
      this.validateForm();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.validation = {};
    },
    validateForm() {
      this.error = "";
      let valid = true;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email == "") {
        this.addError("email", "The email can't be empty");
        valid = false;
      } else {
        if (re.test(String(this.email).toLowerCase())) {
          this.clearError("email");
        } else {
          this.addError("email", "Invalid email format");
          valid = false;
        }
      }
      if (this.password == "") {
        this.addError("password", "The password can't be empty");
        valid = false;
      } else {
        this.clearError("password");
      }
      return valid;
    },
    async next() {
      this.error = "";
      this.showAllErrors = true;
      const valid = this.validateForm();
      if (valid) {
        this.loading = true;
        try {
          // const user = await Auth.signIn(this.email, this.password);
          const logged = await this.$store.dispatch("auth/login", {
            email: this.email,
            password: this.password
          });
          if (logged === true) {
            this.error = "";
            this.email = "";
            this.password = "";
            this.resetForm();
            if (
              this.$route.query?.returnURL &&
              this.$route.query?.returnURL !== ""
            ) {
              this.$router.push(this.$route.query.returnURL);
            } else {
              this.$router.push({ name: "HomeIn" });
            }
          } else {
            throw logged;
          }
        } catch (err) {
          console.log({ err });
          const cognitoMessage = String(err.message)
            .toLowerCase()
            .replaceAll(" ", "-")
            .replaceAll(/[^a-z0-9-]/g, "");
          if (this.$t(`message.cognito.login.${cognitoMessage}`)) {
            this.error = this.$t(`message.cognito.login.${cognitoMessage}`);
          } else {
            this.error = err.message;
          }
        }
        this.loading = false;
      }
    }
  }
};
</script>
